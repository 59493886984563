<!--
 * @Description: 上excel
 * @Author: zhang zhen
 * @Date: 2023-08-16 21:56:03
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-06-17 16:19:05
 * @FilePath: /page-sass/src/views/PublishingRequirements/modules/uploadFIle.vue
-->
<template>
  <div class="uploadView">
    <div class="uploadView-title" style="padding-bottom: 20px;padding-top: 22px;">
      请按照模板文件进行填写,并上传文件
      <a @click="handleGetTemplate" v-show="needDowLoad">
        <div style="display: flex;font-size: 14px; justify-content: center;align-items: center;margin-top: 15px;">
          <img src="/download_icon.png" alt class="arrow" slot="suffixIcon" width="20px" />
          <span>下载-询价模版表</span>
        </div>
      </a>
    </div>

    <a-upload name="file" :multiple="false" :action="uploadAction" :disabled="disabled" :headers="headers" :fileList="fileList"
      @change="handleChangeImage">
      <a-button type="primary" style="width: 248px;height: 40px;font-size: 16px;">
       <img src="/upload_top.png" alt="" class="icon">上传文件
      </a-button>
    </a-upload>
    <p style="color:#8C8C8C;margin-top: 10px;font-size: 12px;line-height: 20px;">严格按照模板文件进行编辑,否则系统无法进行识别</p>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'excelUploadFile',
  props: {
    needDowLoad: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      fileList: [],
      templateParams: null,
    }
  },
  created() {
    this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
  },
  methods: {
    handleInit(item) {
      this.templateParams = item;
    },
    handleGetTemplate(item) {
      if (!this.templateParams) return this.$message.warning('请选择包装材料和需求类型')
      getAction('/v2/purchases/exportDynamicTemplate', { ...this.templateParams }).then(data => {
        if (data.code && data.code == 500) {
          this.$message.warning(data.message)
        } else {
          // 下载
          const { wrapType, purchaseType } = this.templateParams;
          window.open(`/api/v2/purchases/exportDynamicTemplate?wrapType=${wrapType}&purchaseType=${purchaseType}`)
        }
        
      })
    },
    handleChangeImage({ file, fileList }) {
      let fileLists = fileList
      const that = this
      console.log('file.response', file.response, file.status)
      if (file.status === 'done') {
        fileLists = fileLists.splice(-1, 1)
        if (file.response) {
          if (file.response.success) {
            this.$emit('uploadFile', { fileUrl: file.response.data.url, fileId: '' })
          } else {
            this.$message.warning(file.response.message)
          }
        } else {
          console.log(file.response.data)
          that.$confirm({
            title: '文件校验不通过',
            content: '下载重新上传！',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              window.open(file.response.data.url)
            },
            onCancel() { }
          })
        }
      } else if (file.status === 'error') {
        this.$message.warning(file.response.message)
      }
      console.log(fileLists)
      this.fileList = fileLists.filter(i => i.status != 'error')
    }
  }
}
</script>

<style lang="less" scoped>
.uploadView {
  width: 560px;
  background-color: #f9f9f9;
  border-radius: 8px;
  min-height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  &-title {
    width: 100%;
    padding-bottom: 40px;
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    margin-bottom: 30px;
  }
}

.icon {
  width: 20px;
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>

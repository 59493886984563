<template>
  <PDate :placeholder="placeholder" :value="value" style="width: 100%" :removePast="true" :disabled="disabled" @change="handleChange" />
</template>

<script>
import { PDate } from '@/components/easyComponents'
export default {
  components: {
    PDate,
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入'
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let dateStr = this.value
    return {
      momVal: !dateStr ? null : dateStr
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.momVal = null
      } else {
        this.momVal = val
      }
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e)
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>

<style lang="less" scoped></style>